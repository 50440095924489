<template>
  <!--
    The view for the On Call Duty Calendar
  -->
  <Portlet
    :title="$t('onCallDuty')"
    icon="calendar-alt"
    class="calendarView"
  >
    <template slot="buttons">
      <a
        v-if="skisup"
        :href="urlParseMixin_combine(store_getJiraUrl, `browse/${ skisup }`)"
        target="_blank"
        class="btn btn-sm btn-primary mr-3"
      >Log Work</a>
      <font-awesome-icon
        :class="['mr-3 alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
      />
      <button
        v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')" 
        class="btn btn-sm btn-primary float-right" 
        @click="showExport"
      >
        <font-awesome-icon
          class="mr-1"
          icon="file-export"
        />
        <span>
          {{ 'Export' }}
        </span>
      </button>
    </template>

    <div class="row">
      <div
        v-for="(settingRole, index) in roles"
        :key="`li-item${ index }`"
        class="role-legend ml-3 row"
        @click="setRole(settingRole)"
      >
        <div
          :style="'background-color:' + settingRole.color"
          class="mt-1"
        />
        <p :class="['ml-2 mr-4', {'selected-role' : excludedTypes.includes(settingRole)}]">
          {{ settingRole.name }}
        </p>
      </div>
    </div>
    <OnCallDutyCalendar
      ref="onCallDuty"
      :excluded-types="excludedTypes"
      :role-colors="roleColors"
      @setLoading="setLoading"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <SweetModal
      ref="exportModal"
      blocking
      class="overflowHidden"
      title="Export"
    >
      <OnCallDutyExport
        v-if="showExportModal"
        ref="exportView"
        :roles="roles"
        @closeExport="closeExport"
      />
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.exportModal.close()"
      >
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="exportCalendar()"
      >
        <span>{{ 'Export' }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-on-call-duty="true"
      @reload="reloadCalender"
    />
  </Portlet>
</template>

<script>
import { SweetModal } from "sweet-modal-vue";
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { mapGetters } from 'vuex';

export default {
  name: "OnCallDutyCalendarView",
  components: {
    OnCallDutyCalendar: () => import('@/components/OnCallDuty/OnCallDutyCalendar.vue'),
    OnCallDutyExport: () => import('@/components/OnCallDuty/OnCallDutyExport.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue'),
    SweetModal
  },
  mixins: [
    authenticationMixin,
    urlParseMixin
  ],
  data () {
    return {
      excludedTypes: [],
      roles: [],
      loading: false,
      showExportModal: false,
      skisup: null,
      roleColors: ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabed4', '#469990', '#dcbeff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9', '#ffffff', '#000000']
    }
  },
  metaInfo () {
    return {
      title: this.$t('onCallDuty')
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
  },
  created () {
    this.getWorklogSkisup();
    this.getAlturosContactRoles();
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadCalender () {
      this.$refs.onCallDuty.getCalendarData();
    },
    showExport () {
      this.showExportModal = true;
      this.$refs.exportModal.open();
    },
    closeExport () {
      this.$refs.exportModal.close();
      this.showExportModal = false;
    },
    exportCalendar () {
      this.$refs.exportView.exportCalnedar();
    },
    setLoading (val) {
      this.loading = val;
    },
    setRole (val) {
      if(this.excludedTypes.includes(val)) {
        this.excludedTypes = this.excludedTypes.filter(item => item !== val)
      }
      else {
        this.excludedTypes.push(val);
      }
    },
    getAlturosContactRoles () {
      this.axios.get(`/ApplicationSettings/GetApplicationSettingsByKey?key=alturos-contact-role`)
      .then((response) => {
        this.roles = [];
        response.data.forEach((el, index) => {
          this.roles.push({ name: el.value.split(';')[0], color: this.roleColors[index]});
        });
      });
    },
    getWorklogSkisup () {
      this.axios.get(`/ApplicationSettings/GetApplicationSettingsByKey?key=on-call-duty-issue`)
      .then((response) => {
        if(response && response.data) {
          this.skisup = response.data[0].value;
        }
      });
    },
  }
}
</script>

<style scoped>
.role-legend p{
  font-size: 15px
}

.role-legend {
  cursor: pointer;
}

.role-legend p.selected-role {
  font-weight: bold;
  text-decoration: line-through;
}

.role-legend div {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
</style>

<style>
.calendarView .sweet-modal-overlay .sweet-modal {
  overflow: visible;
}
</style>